<template>
  <div class="profile">
    <h1 class="profile__title">
      {{ profile.data.client_name }}
    </h1>
    <div class="profile__content">
      <p>Логин: {{ profile.data.login }}</p>
      <p>Зарегистрирован: {{ profile.data.register_dt }}</p>
      <p>Администратор: <el-checkbox :value="Boolean(profile.data.is_admin)" /></p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Profile",
  computed: {
    ...mapState("profile", ["profile"]),
  },
};
</script>

<style>
.profile__title {
  padding: 20px 40px 0;
  font-size: 24px;
  color: #000000;
}

.profile__content {
  padding: 0 40px;
  color: #000000;
  font-size: 16px;
}
</style>
